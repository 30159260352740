import React from 'react'
import SEO from '../SEO'
import Footer from './Footer'
import Header from './Header'
import './Layout.scss'

interface Props {
  children: React.ReactNode,
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <SEO />
      <Header />
      <div id="app-content">
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
