import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'

interface Props {
  className?: string,
  style?: React.CSSProperties,
}

const Logo: React.FC<Props> = ({ className, style }) => {
  const {
    file: {
      childImageSharp: {
        fluid: imageData,
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Image
      fluid={imageData}
      className={[`uv-logo`, className].join(` `)}
      style={style}
    />
  )
}

export default Logo


