import { Link } from 'gatsby'
import React, { useCallback, useContext, useState } from 'react'
import { slide as Menu } from "react-burger-menu"
import { IoIosMore } from 'react-icons/io'
import { PrimaryButton } from '../Button'
import Logo from '../Logo'
import UserContext from '../UserContext'
import './Header.scss'

const links = [
  { text: `About`, to: `/about` },
  { text: `FAQ`, to: `/faq` },
]

const Header: React.FC<{}> = () => {

  const { user: me } = useContext(UserContext)

  const [menuOpen, setMenuState] = useState(false)
  const setMenuOpen = useCallback(() => setMenuState(true), [])
  const updateMenuState = useCallback(
    ({ isOpen }) => setMenuState(isOpen),
    [setMenuState],
  )

  return (
    <>
    <header id="header">
      <div className="left">
        <Link to="/">
          <Logo />
        </Link>
        <div className="mobile-button" onClick={setMenuOpen}>
          <IoIosMore size="1.5em"/>
        </div>
      </div>
      <div className="right">
        <nav className="links">
          {
            links.map(({ to, text }) => (
              <Link to={to} key={to}>
                {text}
              </Link>
            ))
          }
        </nav>
        {/* {
           me.name ? (
            <nav className="user">
              <PrimaryButton className="create-button" to="/companies/edit">CREATE</PrimaryButton>
              <Link
                to={`/users/${me.id}`}
                title={me.name}
              >
                <div className="profile-picture" style={{backgroundImage: `url("${me.picture}")`}} />
              </Link>
            </nav>
          ) : (
            <nav className="login">
              <PrimaryButton to="/login">Login</PrimaryButton>
            </nav>
          )
        } */}
      </div>
    </header>
    <div id="sidebar">
      <Menu
        left
        isOpen={menuOpen}
        pageWrapId="app-content"
        outerContainerId="gatsby-focus-wrapper"
        customBurgerIcon={false}
        onStateChange={updateMenuState}
      >
        {/* {
          me.name ? (
            <nav className="user">
              <Link
                to={`/users/${me.id}`}
                title={me.name}
              >
                <div className="profile-picture" style={{backgroundImage: `url("${me.picture}")`}} />
              </Link>
              <PrimaryButton className="create-button" to="/companies/edit">CREATE</PrimaryButton>
            </nav>
          ) : null
        } */}
        {links.map(({ to, text }) => (
          <Link
            to={to}
            key={to}
            className="menu-item"
          >
            {text}
          </Link>
        ))}
      </Menu>
    </div>
  </>
  )
}

export default Header