import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Environment, EnvironmentVariables } from '../../lib/Constants'
import type { OpenGraphType } from '../../types/opengraph'

interface Props {
  title?: string,
  description?: string,
  image?: string
  type?: OpenGraphType,
}

const SEO: React.FC<Props> = ({
  title,
  description,
  image,
  type = `website`,
}) => {
  const { pathname } = useLocation()
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        titleTemplate,
        siteUrl,
        social: {
          twitter,
        },
      },
   },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          siteUrl
          social {
            twitter
          }
        }
      }
    }
  `)

  const defaultImage = `${[Environment.production, Environment.staging].includes(EnvironmentVariables.ENVIRONMENT) ? `https` : `http`}://${EnvironmentVariables.BASE_DOMAIN}/icon.png`

  const details = {
    description,
    image: image || defaultImage,
    title: title || defaultTitle,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={details.title}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={details.description} />
      { details.image && <meta name="image" content={details.image} /> }

      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:url" content={details.url} />
      <meta property="og:title" content={details.title} />
      <meta property="og:description" content={details.description} />
      { details.image && <meta property="og:image" content={details.image} />}
      <meta property="og:locale" content="en_SG" />
      <meta property="og:locale:alternate" content="en_US" />
      <meta property="og:locale:alternate" content="en_GB" />
      
      { twitter && <meta name="twitter:creator" content={twitter} /> }
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={details.title} />
      <meta name="twitter.description" content={details.description} />
      { details.image && <meta name="twitter:image" content={details.image} /> }
      <meta name="twitter:image:alt" content={details.description} />
    </Helmet>
  )
}

export default SEO