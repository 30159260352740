import { Link } from 'gatsby'
import React from 'react'
import { IoLogoTwitter, IoMdMail } from "react-icons/io"
import './Footer.scss'


const Footer: React.FC<{}> = () => (
  <footer className="footer">
    <Link to="/legal">Legal</Link>

    <a href="https://twitter.com/BitcornValley">
      <IoLogoTwitter size="1.5em" />
    </a>
    <a href="mailto:hello@unicornvalley.xyz">
      <IoMdMail size="1.5em" />
    </a>
  </footer>
)

export default Footer